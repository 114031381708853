import { action, Action, thunk, Thunk } from "easy-peasy";
import ErrorHandler from "../../web/utils/ErrorHandler";
import Notification from "../../web/utils/Notification";
import http from "../../web/utils/http";
import { get } from 'lodash';
import { log } from "console";

export interface IAuthorModel {
    /*****..........@...ANY TYPE...@.......*****/
    authors: Array<Object>;
    authorFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setAuthor: Action<IAuthorModel, any>;
    setAuthorFromData: Action<IAuthorModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    // loadAuthor: Thunk<IAuthorModel, any>;
    saveAuthor:  Thunk<IAuthorModel, any>;
    updateAuthor:  Thunk<IAuthorModel, any>;
    searchAuthor: Thunk<IAuthorModel, any>;
    loadAuthor: Thunk<IAuthorModel, any>;
    deleteAuthor:  Thunk<IAuthorModel, any>;
}
const AuthorModel : IAuthorModel = {
    authors: [],
    authorFromData: {},

    /*****..........@...ACTION TYPE...@.......*****/
    setAuthor: action((state, payload) => {
        state.authors = payload;
    }),
    setAuthorFromData: action((state, payload) => {
        state.authorFromData = payload;
    }),
     /*****..........@...THUNK TYPE...@.......*****/
     saveAuthor: thunk(async (actions, data) => {
        await http({ "Content-Type": "multipart/form-data" })
            .post('/author', data)
            .then(res => {
                console.log("Akhir api data:", res); // Logs the response
                Notification.success("Changes Saved Successfully.");
            })
            .catch(error => {
                console.error("Error:", error); // Logs the error
                ErrorHandler(error);
                return Promise.reject("error");
            });
    }),
    updateAuthor:thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).put('/author',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Updated Successfully.");
            // actions.searchClients(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),
    searchAuthor:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data, 'title') || "",
            'status': get(data, 'status') || "",
        }
        const response = await http().get('author/search',{params}).then(res=>{
            const { data } = res;
            actions.setAuthor(data);
        }).catch(error=>{
            console.log(error);
        });
    }),
     
    loadAuthor: thunk(async (actions, data) => {
        let params = {
            'page': get(data, 'page') || 1,
            'pageSize': get(data, 'pageSize') || 10,
        };
    
        console.log("Load Author Params:", params); // Log API params
    
        const response = await http()
            .get('author', { params })
            .then((res) => {
                const { data } = res;
                console.log("Load Author Response:", data); // Log API response
                actions.setAuthor(data); // Update state with response
            })
            .catch((error) => {
                console.error("Load Author Error:", error); // Log API error
            });
    }),
    
    deleteAuthor:thunk(async (actions, id) => {
        const response = await http().delete(`author/${id}`).then(res=>{
            actions.searchAuthor(actions);
            Notification.success("Award deleted Successfully.");
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),
}
    
export default AuthorModel;