import React from 'react'
const ManagePostType = React.lazy(()=>import('./web/pages/post/post-type/ManagePostType'))
const ManageTestimonialType = React.lazy(()=>import('./web/pages/testimonials/testimonials-type/ManageTestimonialType'))
const CaseStudyList = React.lazy(() => import('./web/pages/case-study/case-study-list/Index'))
const UserLogin = React.lazy(() => import('./web/pages/Login/UserLogin/Index'))
const AddCaseStudy = React.lazy(() => import('./web/pages/case-study/add-case-study/Index'))
const TestimonialsList = React.lazy(() => import('./web/pages/testimonials/testimonials-list/Index'))
const AddTestimonials = React.lazy(() => import('./web/pages/testimonials/add-testimonials/Index'))
const PostList = React.lazy(() => import('./web/pages/post/post-list/Index'))
const PostMedia = React.lazy(() => import('./web/pages/post/post-media/Index'));
const AddPost = React.lazy(() => import('./web/pages/post/add-post/Index'))
const JobList = React.lazy(() => import('./web/pages/job/job-list/Index'))
const AddJob = React.lazy(() => import('./web/pages/job/add-job/Index'))
const CategoriesList = React.lazy(() => import('./web/pages/categories/categories-list/Index'))
const AddCategories = React.lazy(() => import('./web/pages/categories/add-categories/Index'))
const AddSubCategories = React.lazy(() => import('./web/pages/categories/add-subCategories/Index'))
const ClientList = React.lazy(() => import('./web/pages/client/client-list/Index'))
const AddClient = React.lazy(() => import('./web/pages/client/add-client/Index'))
const OfficialPartnerList = React.lazy(() => import('./web/pages/officialPartner/official-partner-list/Index'))
const AddOfficialPartner = React.lazy(() => import('./web/pages/officialPartner/add-official-Partner/Index'))
const NewsPressRelease = React.lazy(()=>import('./web/pages/news-press-relaese/news-press-release-lists/Index'));
const AddNewsPressRelease = React.lazy(()=>import('./web/pages/news-press-relaese/add-news-press-release/Index'));
const Awards = React.lazy(()=>import('./web/pages/awards/Awards'));
const CreateAwards = React.lazy(()=>import('./web/pages/awards/CreateAwards'));
const GlobalTechSeries = React.lazy(()=>import('./web/pages/global-tech-series/GlobalTechSeries'));
const Feedback = React.lazy(()=>import('./web/pages/feedback/Feedback'));
const FeedbackView = React.lazy(()=>import('./web/pages/feedback/FeedbackView'));
const CreateGlobalTechSeries = React.lazy(()=>import('./web/pages/global-tech-series/CreateGlobalTechSeries'));
const ManageCaseStudyType = React.lazy(()=>import('./web/pages/case-study/case-study-type/ManageCaseStudyType'));
const Types = React.lazy(()=>import('./web/pages/types/Types'));
const CreatePdf = React.lazy(()=>import('./web/pages/pdf/add-pdf/Index'));
const PdfList = React.lazy(() => import('./web/pages/pdf/pdf-list/index'))
const CreateMail = React.lazy(()=>import('./web/pages/mail/add-mail/Index'));
const CreateContent= React.lazy(()=>import('./web/pages/static-content/add-content/index'));
const ContentList= React.lazy(()=>import('./web/pages/static-content/content-list/index'));
const Author = React.lazy(()=>import('./web/pages/author/Author'));
const CreateAuthor = React.lazy(()=>import('./web/pages/author/CreateAuthor'))
const routes = [
  { path: '/case-studies', name: 'Case Study', element: CaseStudyList },  
  { path: '/login', name: 'Login', element: UserLogin },  
  { path: '/create/case-study', name: 'Add Case Study', element: AddCaseStudy },
  { path: '/edit/case-study/:caseStudyId', name: 'Add Case Study', element: AddCaseStudy },
  { path: '/case-study/case-study-types', name: 'Manage Case Study Type', element: ManageCaseStudyType },
  { path: '/testimonial-types', name: 'Manage Testimonial Type', element: ManageTestimonialType },
  { path: '/post-types', name: 'Manage Post Type', element: ManagePostType },
  { path: '/testimonial-list', name: 'Testimonials', element: TestimonialsList },  
  { path: '/create/testimonial', name: 'Add Testimonial', element: AddTestimonials }, 
  { path: '/edit/testimonial/:testimonialId', name: 'Update Testimonial', element: AddTestimonials },
  { path: '/post-list', name: 'Post', element: PostList },
  { path: '/post-media', name: 'Post', element: PostMedia },
  { path: '/create/post', name: 'Add Post', element: AddPost },  
  { path: '/edit/post/:postId', name: 'Update Post', element: AddPost },
  { path: '/jobs', name: 'Job', element: JobList },  
  { path: '/create/job', name: 'Add Job', element: AddJob },
  { path: '/edit/job/:jobId', name: 'Add Job', element: AddJob },
  { path: '/categories', name: 'Categories', element: CategoriesList },  
  { path: '/create/category', name: 'Add Categories', element: AddCategories },
  { path: '/edit/category/:categoryId', name: 'Update Category', element: AddCategories },
  { path: '/create/sub_category', name: 'Add Subcategories', element: AddSubCategories },
  { path: '/edit/sub_category/:categoryId', name: 'Update Subcategories', element: AddSubCategories },
  { path: '/clients', name: 'Client', element: ClientList },  
  { path: '/create/client', name: 'Add Client', element: AddClient },
  { path: '/edit/client/:clientId', name: 'Update Client', element: AddClient },
  { path: '/official-partner-list', name: 'Official Partner', element: OfficialPartnerList },  
  { path: '/create/official-partner', name: 'Add Official Partner', element: AddOfficialPartner },
  { path: '/edit/official-partner/:officalPartnerId', name: 'Update Official Partner', element: AddOfficialPartner },
  { path: '/news-press-release', name: 'News and Press Release', element: NewsPressRelease },
  { path: '/new/news-press-release', name: 'Add new News and Press Release', element: AddNewsPressRelease },
  { path: '/edit/news-press-release/:newsPressReleaseId', name: 'Update News and Press Release', element: AddNewsPressRelease },
  { path: '/awards', name: 'Awards', element: Awards },
  { path: '/types', name: 'Awards', element: Types },
  { path: '/create/award', name: 'Add new Award', element: CreateAwards },
  { path: '/edit/award/:awardId', name: 'Update Award', element: CreateAwards },
  { path: '/authors', name: 'Author', element: Author },
  { path: '/edit/author/:aurhorId', name: 'Update Award', element: CreateAuthor },
  { path: '/create/author', name: 'Add new author', element: CreateAuthor},
  { path: '/global-tech-series', name: 'Global Tech Series', element: GlobalTechSeries },
  { path: '/create/global-tech-series', name: 'Add new Global Tech Series', element: CreateGlobalTechSeries },
  { path: '/edit/global-tech-series/:globalId', name: 'Update Award', element: CreateGlobalTechSeries },
  { path: '/feedback', name: 'Feedbacks', element: Feedback },
  { path: '/view/feedback/:feedbackId', name: 'View Feedback', element: FeedbackView },
  { path: '/pdfs', name: 'Pdfs', element: CreatePdf },
  {path: '/pdf-list', name: 'PdfList', element: PdfList },
  { path: '/create/pdf', name: 'Add new Pdf', element: CreatePdf },
  { path: '/edit/pdf/:pdfId', name: 'Update Pdf', element: CreatePdf },
  { path: '/mails', name: 'Mails', element: CreateMail },
  { path: '/create/mail', name: 'Add new Mail', element: CreateMail },
  { path: '/edit/mail/:mailId', name: 'Update Mail', element: CreateMail },
  { path: '/static-resource', name: 'resource', element: CreateContent },
  { path: '/static-resource-file', name: 'resource-file', element: ContentList}
  
]

export default routes
